@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@800&display=swap");

* {
  text-align: left;
  color: white;
  background-color: #ff00d5;
  scroll-behavior: smooth;
}

.enter-div {
  animation: 2s ease-out 1s slidein;
  animation-fill-mode: forwards;
  transform: translateX(-100%);
  position: relative;
  z-index: 55;
}

h1 {
  font-family: "Work Sans", sans-serif;
  font-size: 16vw;
  margin-bottom: 0;
  padding-left: 1vw;
  animation: 4.5s ease-in 0s colorchange;
  color: black;
}

.sectionheader {
  transition: all 0.5s ease-in-out;
}

.sectionheader:hover {
  color: black;
  transition: all 1s ease-in-out;
}

h2 {
  font-size: 9vw;
  margin: 0;
  padding-left: 1vw;
  padding-bottom: 3vh;
  padding: top 0vh;
}

h3 {
  font-size: 5vw;
  margin: 0;
}

h4 {
  margin: auto;
}

a {
  margin: auto;
}

button {
  background-color: #ff00d5;
  border: 0;
  padding: 0px 1vw;
  margin: 0px 1vw;
  border-bottom: 1px solid black;
  height: 70%;
}

button a {
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.3rem;
  overflow: hidden;
  letter-spacing: 0.3rem;
  transition: all 0.5s ease-in-out;
}

button a:hover {
  color: black;
  font-weight: bolder;
  transition: all 0.3s ease-in-out;
}

.icon {
  flex: 1/3;
}

#home {
  padding: 20vh 0 10vh;
  width: 50vw;
  min-width: 100vw;
}

.subtitle {
  display: flex;
  font-size: 13vw;
}

.subtitle h2 {
  padding-left: 0;
  padding-bottom: 0;
}

.menubar {
  position: fixed;
  width: 100%;
  height: 7vh;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  z-index: 555;
}

.menubar div {
  background-color: #ff00d5;
  padding-top: 1vh;
}
.mobilebar {
  position: fixed;
  width: 100%;
  height: 7vh;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-start;
  font-size: 6vh;
}

.mobile-icon {
  align-self: center;
  transition: all 0.4s ease-in-out;
}

.submenu.mounted {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 7vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  padding: 11vh 0vh;
  transition: all 4s ease-in-out;
}

.submenu {
  display: none;
}

.submenu button {
  height: 15vh;
  padding: 0vw 1vw;
  text-align: center;
  justify-content: center;
  letter-spacing: 0px;
}

.submenu button:hover {
  background-color: #ffffff;
  transition: all 1s ease-in-out;
}

.submenu button:hover a {
  color: #ff00d5;
  font-weight: bold;
}

.submenu #specialbutton {
  border: 0;
}

.submenu #specialbutton:hover {
  border: 0;
}

#specialbutton {
  border: 1px solid #ffffff;
  margin-right: 1vw;
}

#specialbutton:hover {
  border: 1px solid #000000;
  background-color: aqua;
  transition: all 0.4s ease-in-out;
}
#specialbutton:hover a {
  background-color: inherit;
  font-weight: bold;
  color: black;
  transition: none;
}

.appbar {
  background-color: #ff00d5;
}

.outlined-button {
  border: #400035 10px solid;
}

#about {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.about img {
  height: 70vh;
}

.about-text {
  flex: 1;
  padding: 0.5em;
  font-size: 0.8em;
}

.about-text span {
  font-size: 1.3em;
}

#projects {
  padding-bottom: 0vh;
}

#projects p {
  padding-left: 2vw;
  margin-top: 0;
}
.project-info-div {
  display: flex;
  flex-direction: column;
  padding: 0;
  row-gap: 5vw;
}

.project-container {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.05);
}

.image-div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.image-div img {
  width: 100%;
}

.project-text {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  text-align: center;
}

.project-text h4 {
  text-align: center;
  margin-bottom: 2vh;
  border-bottom: solid 1px black;
  padding-bottom: 1vh;
  background-color: transparent;
}

.project-text p {
    /* border:solid 3px white; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    background-color: transparent;
    padding: 0vh 1vw;
  }

.web-link {
  color: black;
  font-family: "Work Sans", sans-serif;
  font-weight: normal;
  overflow-wrap: break-word;
  background-color: transparent;
  text-align: center;
  padding: 0vw 1vw 1vh 1vw;
  width: 90%;
}
.web-link:hover {
  color: white;
}
 .get-in-touch {
  display: flex;
  flex-direction: column;
}

.get-in-touch p {
  padding-left: 1vw;
}

#contact {
  padding-top: 10vh;
}

#contact p {
  padding-left: 2vw;
  margin-top: 0;
}

.get-in-touch {
  display: flex;
  flex-direction: column;
  column-gap: 5vw;
  width: 100%;
  align-items: center;
  margin-top: 5vh;
}

.links {
  flex: 1;
  display: flex;
  column-gap: 1vw;
  margin: auto;
  margin-bottom: 2vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.links a {
  color: white;
  font-family: "Work Sans", sans-serif;
  background-color: transparent;
  text-align: center;
  font-size: calc(10px + 2vmin);
  width: 33%;
  transition: all 0.5s ease-in-out;
}

.links a:hover {
  font-size: calc(10px + 3.5vmin);
  transition: all 1s ease-in-out;
  background-color: transparent;
}

.contact-form {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.1)
    ),
    linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0)
    ),
    url("bw-ads.jpg");
  width: 100vw;
  margin: auto;
  display: flex;
}

.contact-body {
  width: 80vw;
  margin: 5vh auto;
  background-color: white;
}

.contact-body h4 {
  padding: 4vh 1vh 2vh;
  text-align: center;
}

.form-table > tbody > tr > td > :nth-child(1) {
  color: black;
  display: flex;
  text-align: start;
  vertical-align: top;
}

.form-table {
  border-spacing: 0;
  text-indent: 0;
  width: 100%;
  padding-right: 2vw;
  padding-left: 2vw;
}

.form-table > tbody > tr > td {
  border-bottom: 1px #63666a dotted;
  padding: 1vh;
  font-size: calc(10px + 1vmin);
}

#message {
  height: 30vh;
}

input {
  width: 100%;
  margin-right: 7vw;
  font-size: large;
}

form {
  padding-top: 1vh;
}

#submit {
  margin: 2vh 0vh 2vh 5vh;
  height: 4vh;
  width: 9vh;
  text-align: center;
  font-size: calc(10px + 1vmin);
  font-family: "Work Sans", sans-serif;
}

#submit:hover {
  border: 1px solid #000000;
  background-color: aqua;
  transition: all 0.4s ease-in-out;
}

.Body {
  background-color: #ff00d5;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #000000;
}

@media screen and (max-width: 979px) {
  .about img {
    margin: auto;
    height: 70vmin;
    width: 100vmin;
    object-fit: cover;
    object-position: left 15%;
  }

  * {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 980px) {
  * {
    overflow-x: hidden;
  }

  #home {
    padding-top: 20vh;
    padding-bottom: 0;
    height: 70vh;
    overflow: visible;
  }

  #about {
    padding-top: 20vh;
    padding-bottom: 0vh;
  }

  h1 {
    font-size: 165px;
  }

  .subtitle {
    font-size: 130px;
    overflow: visible;
  }

  h2 {
    font-size: 90px;
  }
  h3 {
    font-size: 50px;
  }

  section {
    height: 100%;
  }

  .about {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px;
    height: 100%;
    margin-left: 0;
  }

  .about div {
    flex: 2;
  }

  .about img {
    flex: 1/2;
    object-fit: contain;
  }

  .about-text {
    flex: 2;
    padding: 0.5em;
    font-size: 0.7em;
  }

  .about-text span {
    font-size: 1.3em;
  }

  #projects {
    padding-top: 10vh;
    /* height: 100vh; */
    /* background-color: red; */
  }

  .project-info-div {
    flex-direction: row;
    padding: 2vh 2vw;
    column-gap: 1vw;
  }

  .project-info-div:hover .project-container:not(:hover){
    /* background-color: white; */
    transform: scale(0.85);
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 1s ease-in-out;
  }

  .project-container {
    transition: all 0.5s ease-in-out;
    overflow: hidden;
  }
  
  .project-container:hover {
    transform: scale(1.2);
    background-color: rgba(255, 255, 255, 0.5);
    transition: all 0.5s ease-in-out;
  }
  
  .project-container:hover .project-text h4{
    color:black;
    transition: all 1s ease-in-out;
    text-decoration: underline;
    text-decoration-color: black;
  }

  .image-div {
    flex: 1;
    height: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .project-text {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 1vw;
    background-color: transparent;
  }
  
  .project-text h4 {
    text-align: center;
    margin-bottom: 2vh;
    padding-bottom: 1vh;
    background-color: transparent;
  }
  
    
  .web-link {
    font-family: "Work Sans", sans-serif;
    font-weight: normal;
    font-size: 70%;
    transition: all 0.5s ease-in-out;
    text-align: center;
    padding-bottom: 1vh;
    background-color: transparent;
  }

  .web-link:hover {
    color: white;
  }

  .get-in-touch {
    padding: 0vh;
    margin: 0px;
  }
}

@keyframes slidein {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes colorchange {
  0% {
    color: white;
  }
  50% {
    color: white;
  }
  100% {
    color: black;
  }
}